// / eslint-disable react-hooks/exhaustive-deps /
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const AppContext = createContext<any>(null);

interface IProps {
    children: React.ReactNode
}

export const AppProvider = ({ children }: IProps) => {

    // translation from i18n
    const { i18n } = useTranslation();

    // handle change language
    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
    };

    return (
        <AppContext.Provider value={{ changeLanguage }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);