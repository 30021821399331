import React from "react";
import { getPetDetails, phoneNumbers } from "../../interface";

interface Props {
  getPetDetails: getPetDetails;
}

const PhoneNumberSection = ({ getPetDetails }: Props) => {
  const baseURL = process.env.REACT_APP_PHONE_IMAGES_BASE_URL;
  return (
    <>
      {getPetDetails?.phoneNumbers.length === 0 ? null : (
        <div className="call-blk">
          {getPetDetails?.phoneNumbers?.map(
            (items: phoneNumbers, index: number) => (
              <a
                key={index}
                href={`tel:${items?.countryCode}${items?.phone}`}
                className="callaction"
              >
                <span className="callaction-letter">
                  <img
                    style={{ borderRadius: "50px" }}
                    src={baseURL + items?.image}
                    alt="Phone"
                  />
                </span>
                <span className="callaction-detail">
                  <span className="callaction-text">{items.name}</span>
                  <span className="callaction-number">
                    {items?.countryCode} {items?.phone}
                  </span>
                </span>
                <span className="phone-icon">
                  <img src="assets/images/phone.svg" alt="Phone" />
                </span>
              </a>
            )
          )}
        </div>
      )}
    </>
  );
};

export default PhoneNumberSection;
