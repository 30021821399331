import React from "react";
import i18n from "../../translation/i18next";

interface Props {
  setOpenLocationModal: any;
}

const LocationSection = ({ setOpenLocationModal }: Props) => {
  return (
    <div
      className="send-location-blk"
      onClick={() => {
        setOpenLocationModal(true);
      }}
    >
      <button className="sendlocation">
        <span className="btn-text">{i18n.t("Send location to owner")}</span>
        <span className="btn-icon">
          <img src="assets/images/send-location-icon.svg" alt="Location" />
        </span>
      </button>
    </div>
  );
};

export default LocationSection;
