import "./App.css"
import { Toaster } from 'react-hot-toast';
import Navigation from './Router/Navigation';
import { AppProvider } from "./Context/languageContext";

function App() {

  return (
    <AppProvider>
      <Toaster />
      <Navigation />
    </AppProvider>
  );
}

export default App;

