import React from "react";
import { getPetDetails } from "../../interface";
import i18n from "../../translation/i18next";

interface Props {
  getPetDetails: getPetDetails;
}

const HealthInformationSection = ({ getPetDetails }: Props) => {
  return (
    <>
      {!getPetDetails?.petHealth ? null : (
        <div className="detail-slider-item z-0">
          <h2>{i18n.t("Health Informations")}</h2>
          <div className="detail-content-blk">
            <div className="detail-top-blk">
              <div className="detail-img-blk">
                <img src="assets/images/health.svg" alt="Health" />
              </div>
              <div className="content-detail">
                <h3>{getPetDetails?.petDetail?.name}</h3>
                <span className="text-blk">
                  {" "}
                  {i18n.t("Health Informations")}
                </span>
              </div>
            </div>
            <div className="inline-content-wrapper">
              <div className="inline-content-blk">
                <span className="txt-heading">{i18n.t("Allergies")} :</span>
                <p>{getPetDetails?.petHealth?.allergies}</p>
              </div>
              <div className="inline-content-blk">
                <span className="txt-heading">Medication required:</span>
                <p>{getPetDetails?.petHealth?.medicationRequired}</p>
              </div>
              <div className="inline-content-blk">
                <p>
                  <span className="txt-bold">Neutered / Spayed:&nbsp;</span>
                  {getPetDetails?.petHealth?.neutered === true
                    ? i18n.t("Yes")
                    : i18n.t("No")}
                </p>
              </div>
              {getPetDetails?.petBehavior?.additionalInfo?.length ? (
                <div className="inline-content-blk">
                  <span className="txt-heading">
                    {i18n.t("Additional information")} :
                  </span>
                  <p>{getPetDetails?.petHealth?.additionalInfo}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HealthInformationSection;
