/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../components/header";
import TopSection from "./topSection";
import axios from "axios";
import BottomSection from "./bottomSection";
import { getPetDetails } from "../interface";
import ProtectedPage from "./protectedProfile";
import NotAssignPetPage from "./notAssignPet";
import alertTost from "../components/AlertToast";
import ProtectedTag from "./protectedProfile/protectedTag";
import i18n from "../translation/i18next";
import { useAppContext } from "../Context/languageContext";
import { useLocation } from "react-router-dom";

interface Props {}

const MainScren = (props: Props) => {
  const locationState = useLocation();
  const { changeLanguage } = useAppContext();
  const [getPetDetails, setGetPetDetails] = useState<getPetDetails | any>();
  const [isLoading, setSetisloading] = useState<boolean>(false);
  const [privatePet, setPrivatePet] = useState<boolean>(false);
  const [notActivatedTag, setNotActivatedTag] = useState<boolean>(false);
  const [notAssignTag, setNotAssignTag] = useState<boolean>(false);
  const [notValidTag, setNotValidTag] = useState<boolean>(false);
  const [notFoundTag, setNotFoundTag] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(true);
  const [isLocationLoading, setIsLocationLoading] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [flag, setFlag] = useState<boolean>(false);

  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getCurrentLocation = async () => {
    if ("geolocation" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((position) => {
              const latlng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              setCurrentLocation({
                petId: getPetDetails?.data?.petDetail?._id,
                lat: latlng.lat,
                lng: latlng.lng,
                address: "",
              });
              setFlag(true);
            });
          } else if (result.state === "prompt") {
            // Show a permission prompt to the user
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const latlng = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                setCurrentLocation({
                  petId: getPetDetails?.data?.petDetail?._id,
                  lat: latlng.lat,
                  lng: latlng.lng,
                  address: "",
                });
                setFlag(true);
              },
              () => {
                alertTost("Unable to retrieve your location.", "error");
              }
            );
          } else if (result.state === "denied") {
            alertTost("Location access denied.", "error");
            setConfirmationModal(false);
            setOpenLocationModal(true);
          }
        })
        .catch((error) => {
          console.error("Error while checking location permission:", error);
          alertTost(
            "An error occurred while checking location permission.",
            "error"
          );
          setConfirmationModal(false);
          setOpenLocationModal(true);
        });
    } else {
      alertTost("Geolocation is not available in your browser.", "error");
      setConfirmationModal(false);
      setOpenLocationModal(true);
    }
  };

  useEffect(() => {
    if (flag) {
      sendCurrentLocation();
    }
  }, [flag]);

  async function sendCurrentLocation() {
    setIsLocationLoading(true);
    try {
      const resposne = await axios.post(
        `${baseUrl}web/sendlocation`,
        currentLocation
      );
      if (resposne) {
        setIsLocationLoading(false);
        setConfirmationModal(false);
        alertTost("address send successfully", "success");
      }
    } catch (error) {
      setConfirmationModal(false);
      setIsLocationLoading(false);
      alertTost("Something went wrong", "error");
    }
  }

  useEffect(() => {
    setSetisloading(true);
    let latitude = null;
    let longitude = null;

    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition((position) => {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
          });
        }
      });
    }

    if (locationState?.state?.data) {
      setGetPetDetails(JSON.parse(locationState?.state?.data));
      setSetisloading(false);
      setConfirmationModal(true);
    } else if (locationState?.state?.error) {
      let err = locationState?.state?.error;
      if (err?.response?.data?.message === "pet profile is private") {
        setPrivatePet(true);
      }
      if (err?.response?.data?.message === "not assigned to any pet") {
        setNotAssignTag(true);
      }
      if (err?.response?.data?.message === "pet tag is not active") {
        setNotActivatedTag(true);
      }
      if (err?.response?.data?.message === "not valid wooftag") {
        setNotValidTag(true);
      }
      if (err?.response?.status === 403 || 404) {
        setNotFoundTag(true);
      }

      setSetisloading(false);
    }

    setConfirmationModal(true);
  }, []);

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        if (res?.status === 200 && res) {
          changeLanguage(res.data?.languages.split(",")[0]);
        }
      })
      .catch((err) => {
        alertTost(err.message, "error");
      });
  }, []);

  const petName = getPetDetails?.data?.petDetail?.name;
  const PresentLocationText = i18n.t(
    "We would like to share your present location with the owner of They will only be able to view your location at the moment of sending, and it will not be continuously monitored.",
    { petName }
  );

  const NotifyText = i18n.t(
    "A notification will appear, requesting permissions to access your location once. Kindly click the 'Allow' button to ensure safe return home.",
    { petName }
  );

  const returnChild = () => {
    if (privatePet === true) return <ProtectedPage />;
    if (notAssignTag === true) return <NotAssignPetPage />;
    if (notActivatedTag === true) return <ProtectedTag />;
    if (notValidTag === true) return <NotAssignPetPage />;
    if (notFoundTag === true) return <NotAssignPetPage />;
    else
      return (
        <div>
          <Header getPetDetails={getPetDetails?.data} />
          <TopSection
            openLocationModal={openLocationModal}
            setOpenLocationModal={setOpenLocationModal}
            getPetDetails={getPetDetails?.data}
            getCurrentLocation={getCurrentLocation}
          />
          <BottomSection getPetDetails={getPetDetails?.data} />

          {confirmationModal && (
            <div className="modal-overlay">
              <div
                className="modal fade common-modal show d-block"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered justify-content-center ">
                  <div className="modal-content">
                    <div className="modal-body p-0">
                      <div className="location-modal-block">
                        <h1 className="fw-bold">
                          {i18n.t("This pet may be lost")}
                        </h1>
                        <p className="mb-0 fw-normal">
                          {PresentLocationText.replace(
                            /{Petname}/g,
                            `${getPetDetails?.data?.petDetail?.name}`
                          )}
                          {/* We would like to share your present location with the
                          owner of{" "}
                          <b>{getPetDetails?.data?.petDetail?.name}.</b>They
                          will only be able to view your location at the moment
                          of sending, and it will not be continuously monitored. */}
                        </p>
                        <p>
                          {NotifyText.replace(
                            /{dogName}/g,
                            `${getPetDetails?.data?.petDetail?.name}`
                          )}
                          {/* A notification will appear, requesting permissions to
                          access your location once. Kindly click the "Allow"
                          button to ensure{" "}
                          {getPetDetails?.data?.petDetail?.name} safe return
                          home. */}
                        </p>
                        <div className="condition-btns-blk pointer-event">
                          <button
                            className="accept-btn"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                            disabled={isLocationLoading}
                          >
                            {i18n.t("Yes, Okay")}
                            <span className="md-paw">
                              <img
                                src="assets/images/white_image_paw.svg"
                                alt="map"
                              />
                            </span>
                          </button>
                          <button
                            className="cancel-btn pointer-event"
                            onClick={() => setConfirmationModal(false)}
                          >
                            {i18n.t("Ignore")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
  };

  return returnChild();
};

export default MainScren;
