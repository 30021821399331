import React from "react";
import "./protectedPage.css";
interface Props {}

const ProtectedTag = (props: Props) => {
  return (
    <div className="protect-page-wrapper">
      <div className="main-protectPage ">
        <div className="inner-main">
          <div className="rounded-image">
            <img src="/assets/images/Lock.svg" alt="" />
          </div>
          <h3>This pet tag is currently de-active</h3>
          <p>Ask the owner(s) for activate tag</p>
        </div>
      </div>

      <div className="bottom-div">
        <div className="bottom-inner-content">
          <h2>Are you curious?</h2>
          <p>Get Your Own Wooftag Here</p>
        </div>
        <div className="bottom-inner-img">
          <img width={35} src="/assets/images/Buy.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ProtectedTag;
