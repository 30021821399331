import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import CustomMap from "./map";
import { getPetDetails } from "../interface";
import i18n from "../translation/i18next";

interface Props {
  openLocationModal: boolean;
  setOpenLocationModal: any;
  latlng: any;
  setLatLng: any;
  openMap: boolean;
  setOpen: any;
  handleCloseMapModal: () => void;
  handleConfirm: () => void;
  handleOpenModalInput: () => void;
  isLoading: boolean;
  getPetDetails: getPetDetails;
  selectLocation: boolean;
  setSelectLocation: any;
}

const CustoModal = ({
  openLocationModal,
  setOpenLocationModal,
  latlng,
  setLatLng,
  openMap,
  setOpen,
  handleCloseMapModal,
  handleConfirm,
  handleOpenModalInput,
  isLoading,
  getPetDetails,
  selectLocation,
  setSelectLocation,
}: Props) => {
  const baseUrl = process.env.REACT_APP_PET_IMAGES_URL;

  return (
    <div>
      {openLocationModal && (
        <div className="modal-overlay">
          <div
            className="modal fade common-modal show d-block"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center ">
              <img
                width={35}
                className="cross-icon"
                src="/assets/images/cross-icon.svg"
                alt=""
                onClick={() => handleCloseMapModal()}
              />
              <OutsideClickHandler onOutsideClick={() => handleCloseMapModal()}>
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="location-modal-block">
                      <div>
                        <div className="dog-img-inner-location">
                          <img
                            src={`${baseUrl}${getPetDetails?.petDetail.image}`}
                            alt="dog"
                          />
                        </div>
                      </div>
                      <h3>{i18n.t("Your location function is deactivated")}</h3>
                      <p>
                        {i18n.t(
                          "To help 'dog name' and inform the owner. please choose one of the option below"
                        )}
                      </p>
                      <div className="condition-btns-blk">
                        <button
                          className="accept-btn"
                          onClick={() => {
                            setOpen(true);
                            setOpenLocationModal(false);
                          }}
                        >
                          {i18n.t("Select location")}
                          <span className="md-paw">
                            <img src="assets/images/map-logo.svg" alt="map" />
                          </span>
                        </button>
                        <button
                          className="cancel-btn"
                          onClick={handleOpenModalInput}
                        >
                          {i18n.t("Enter an address")}
                          <span className="md-paw">
                            <img src="assets/images/edit-icon.svg" alt="map" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}

      {openMap && (
        <div className="modal-overlay">
          <div
            className="modal fade modalfullscreen common-modal show d-block"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOpenLocationModal(false);
                  setOpen(false);
                }}
              >
                <div className="modal-content map-style">
                  <div className="modalheader">
                    <span className="wooflogo">
                      <img
                        src="/assets/images/wooftag-clr-logo.svg"
                        alt="Logo"
                      />
                    </span>
                    <span className="closemap">
                      <img
                        width={35}
                        className="cross-icon"
                        src="/assets/images/cross-icon.svg"
                        alt=""
                        onClick={() => setOpen(false)}
                      />
                    </span>
                  </div>
                  <CustomMap
                    getLatlng={latlng}
                    setGetLngLng={setLatLng}
                    setSelectLocation={setSelectLocation}
                  />

                  {
                    <div className="inner-map-modal-main">
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          className="image-logo"
                          src={`${baseUrl}${getPetDetails?.petDetail.image}`}
                          alt="dog"
                        />
                        <div className="d-flex flex-column align-items-start">
                          <h3>{i18n.t("Where did you find Pape?")}</h3>
                          <p>{i18n.t("Tap on the map to mark the location")}</p>
                        </div>
                      </div>
                      <button
                        className="accept-btn"
                        type="button"
                        onClick={() => handleConfirm()}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <img
                            width={30}
                            src="/assets/images/woofAnimtedLoading.gif"
                            alt=""
                          />
                        ) : (
                          i18n.t("Confirm & Send")
                        )}
                        <span className="md-paw">
                          <img
                            className="send-icon"
                            width={30}
                            style={{ filter: "invert(1)" }}
                            src="assets/images/send.png"
                            alt="send"
                          />
                        </span>
                      </button>
                    </div>
                  }
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustoModal;
