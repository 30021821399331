import * as Yup from "yup"

const enterAddressInitialValues = {
    enterAddress: '',
};

const enterAddressValidationSchema = Yup.object().shape({
    enterAddress: Yup.string().required('Address is required*'),
});

export {
    enterAddressInitialValues,
    enterAddressValidationSchema
}
