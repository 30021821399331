import React from "react";
import { getPetDetails } from "../../interface";
import alertTost from "../../components/AlertToast";
import i18n from "../../translation/i18next";

interface Props {
  getPetDetails: getPetDetails;
}

const RegisterationSection = ({ getPetDetails }: Props) => {
  const copyToClipboard = (text: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    alertTost("Text Copied", "success");
  };

  const openInGoogleMaps = () => {
    const addressQuery = encodeURIComponent(
      `${getPetDetails?.petVet?.houseNumber} ${getPetDetails?.petVet?.city} ${getPetDetails?.petVet?.country}`
    );
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <>
      {!getPetDetails?.petVet ? null : (
        <div className="detail-slider-item z-0">
          <h2>Vet &amp; {i18n.t("Registration")}</h2>
          <div className="detail-content-blk">
            <div className="detail-top-blk">
              <div
                className="detail-img-blk pointer-event"
                onClick={openInGoogleMaps}
              >
                <img src="assets/images/vet-plus.svg" alt="Doctor" />
              </div>
              <div className="content-detail">
                <h3 onClick={openInGoogleMaps} className="pointer-event">
                  Vet {getPetDetails?.petVet?.doctorName}
                </h3>
                <span
                  onClick={openInGoogleMaps}
                  className="text-blk pointer-event"
                >
                  {getPetDetails?.petVet?.houseNumber}
                </span>
                <span
                  onClick={openInGoogleMaps}
                  className="text-blk pointer-events pointer-event"
                >
                  {getPetDetails?.petVet?.pinCode} {getPetDetails?.petVet?.city}
                </span>
                <span className="text-blk">
                  {getPetDetails?.petVet?.countryCode}{" "}
                  {getPetDetails?.petVet?.phone}
                </span>
              </div>
            </div>

            <div className="call-btn-blk">
              <a
                href={`tel:${getPetDetails?.petVet?.countryCode}${getPetDetails?.petVet?.phone}`}
              >
                <button className="commonbtn">
                  <span className="btn-text">{i18n.t("Call The Vet")}</span>
                  <span className="btn-icon">
                    <img src="assets/images/phone-icon.svg" alt="Phone" />
                  </span>
                </button>
              </a>
            </div>

            <div className="inline-content-wrapper d-flex flex-column ">
              {getPetDetails?.petVet?.microchip?.id &&
              getPetDetails?.petVet?.microchip?.enabled === true ? (
                <div className="inline-content-blk d-flex justify-content-between align-items-center">
                  <div>
                    <span className="txt-heading">
                      {i18n.t("Microchip ID")} :
                    </span>
                    <p>{getPetDetails?.petVet?.microchip?.id}</p>
                  </div>
                  <div
                    onClick={() =>
                      copyToClipboard(getPetDetails?.petVet?.microchip?.id)
                    }
                  >
                    <img src="/assets/images/copy.svg" alt="" />
                  </div>
                </div>
              ) : null}

              {getPetDetails?.petVet?.tasso?.id &&
              getPetDetails?.petVet?.tasso?.enabled === true ? (
                <div className="inline-content-blk d-flex justify-content-between align-items-center">
                  <div>
                    <span className="txt-heading">
                      {" "}
                      {i18n.t("Tasso ID (TKN )")} :
                    </span>
                    <p>{getPetDetails?.petVet?.tasso?.id}</p>
                  </div>
                  <div
                    onClick={() =>
                      copyToClipboard(getPetDetails?.petVet?.tasso?.id)
                    }
                  >
                    <img src="/assets/images/copy.svg" alt="" />
                  </div>
                </div>
              ) : null}

              <div className="inline-content-blk">
                <span className="txt-heading">
                  {i18n.t("Registrated at Findefix")} :
                </span>
                <p>
                  {getPetDetails?.petVet?.registredAtFindFix === true
                    ? i18n.t("Yes")
                    : i18n.t("No")}
                </p>
              </div>
            </div>
            {getPetDetails?.petVet?.registredAtFindFix === true ? (
              <div
                className="search-btn-blk"
                onClick={() =>
                  window.open(
                    "https://www.findefix.com/haustier-vermisst-gefunden/mikrochip-nummer-pruefen/"
                  )
                }
              >
                <button className="commonbtn">
                  <span className="btn-text">
                    {" "}
                    {i18n.t("Search at Findefix")}
                  </span>
                  <span className="btn-icon">
                    <img src="assets/images/search.svg" alt="Search" />
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterationSection;
