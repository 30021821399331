import toast from 'react-hot-toast';

const alertTost = (content: string, type: string) => {
    if (type === "success") {
        toast.success(content, {
            duration: 2000,
            position: 'top-center',
            className: '',

            // Custom Icon
            style: {
                borderRadius: '10px',
                background: '#4caf50',
                color: '#fff',
            },

            // Change colors of success/error/loading icon
            iconTheme: {
                primary: 'white',
                secondary: 'black',
            },

            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    }
    else if (type === "error") {
        toast.error(content, {
            duration: 2000,
            position: 'top-center',
            className: '',

            // Custom Icon
            style: {
                borderRadius: '10px',
                background: "#c91b0e",
                color: '#fff',
                backgroundSize: "cover"
            },

            // Change colors of success/error/loading icon
            iconTheme: {
                primary: 'white',
                secondary: 'black',
            },

            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        });
    }

};

export default alertTost;