import React from "react";
import { getPetDetails } from "../interface";

interface IProps {
  getPetDetails: getPetDetails;
}

const Header = ({ getPetDetails }: IProps) => {
  const baseUrl = process.env.REACT_APP_PET_IMAGES_URL;

  return (
    <section
      className={
        getPetDetails?.petDetail?.lost === true
          ? "hero-section lost"
          : "hero-section"
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="hero-blk">
              <div className="logo-blk">
                <img
                  src={
                    getPetDetails?.petDetail?.lost === true
                      ? "/assets/images/wooftag-white-logo.svg"
                      : "assets/images/wooftag-clr-logo.svg"
                  }
                  alt="Logo"
                />
              </div>
              <div className="dog-img-outer-blk">
                <div className="dog-img-blk">
                  <img
                    src={`${baseUrl}${getPetDetails?.petDetail.image}`}
                    alt="dog"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getPetDetails?.petDetail?.lost === true ? (
        <span className="badge-blk bg-red">lost</span>
      ) : null}
    </section>
  );
};
export default Header;
