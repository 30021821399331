import React from "react";
import "./protectedPage.css";
import i18n from "../../translation/i18next";
interface Props {}

const ProtectedPage = (props: Props) => {
  return (
    <div className="protect-page-wrapper">
      <div className="main-protectPage ">
        <div className="inner-main">
          <div className="rounded-image">
            <img src="/assets/images/Lock.svg" alt="" />
          </div>
          <h3>{i18n.t("This pet profile is currently set to private")}</h3>
          <p>{i18n.t("Ask the owner(s) for release")}</p>
        </div>
      </div>

      <div className="bottom-div">
        <div className="bottom-inner-content">
          <h2>{i18n.t("Are you curious?")}</h2>
          <p>{i18n.t("Get Your Own Wooftag Here")}</p>
        </div>
        <div className="bottom-inner-img">
          <img width={35} src="/assets/images/Buy.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ProtectedPage;
