import React from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "./marker";

interface IProps {
    getLatlng: any
    setGetLngLng: any
    setSelectLocation: any
}

export default function CustomMap({ getLatlng, setGetLngLng, setSelectLocation }: IProps) {

    const defaultProps = {
        center: {
            lat: 52.5200,
            lng: 13.4050
        },
        zoom: 10
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onClick={(e: any) => {
                    setGetLngLng({
                        lat: e.lat,
                        lng: e.lng
                    })
                    setSelectLocation(true)
                }}
            >
                <Marker lat={getLatlng.lat} lng={getLatlng.lng} text="My Marker" />
            </GoogleMapReact>
        </div>
    );
}