/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./notAssignPet.css";
import { useNavigate } from "react-router-dom";
import i18n from "../../translation/i18next";

interface Props {}

const NotAssignPetPage = (props: Props) => {
  const navigate = useNavigate();

  const [OS, setOS] = useState("unknow");

  function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];

    let os = "unknow";

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const returnPlayStoreUrl = () => {
    if (OS == ("iOS" || "Mac OS")) {
      return window.open("https://apps.apple.com/us/app/wooftag/id6467188257");
    } else if (OS == "Windows") {
      return window.open(
        "https://play.google.com/store/apps/details?id=com.wooftag"
      );
    } else if (OS == "Android") {
      return window.open(
        "https://play.google.com/store/apps/details?id=com.wooftag"
      );
    } else {
      return window.open(
        "https://play.google.com/store/apps/details?id=com.wooftag"
      );
    }
  };

  useEffect(() => {
    let os = getOS();
    setOS(os);
  }, []);

  return (
    <>
      <section className="content-section">
        <div className="container g-0">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="d-flex align-items-center flex-column justify-content-center vh-100 gap-4">
                <img width={100} src="/assets/images/welcomeBg.svg" alt="" />
                <h3 className="m-0 text-center w-100 text-capitalize tag-title">
                  {i18n.t("Setup your new wooftag")}
                </h3>
                <p className="m-0 text-center tag-description ">
                  {i18n.t(
                    "Now your pets are in one place and always find their way home quickly"
                  )}
                </p>

                <div className="d-flex flex-column w-100 align-items-center gap-3 ">
                  <a
                    className="sendlocation justify-content-center text-white"
                    style={{ padding: "18px", cursor: "pointer" }}
                    onClick={() => returnPlayStoreUrl()}
                  >
                    <span className="download-btn">
                      {i18n.t("Download Free App")}
                    </span>
                  </a>
                  <button
                    className="cancel-btn"
                    style={{ maxWidth: "600px" }}
                    onClick={() => navigate("/wooftagofficial")}
                  >
                    {i18n.t("View Example Profile")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotAssignPetPage;
