import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EnTranslation from "./enTranslation.json";
import DeTranslation from "./deTranslation.json";

const resources = {
  en: {
    translation: EnTranslation,
  },
  de: {
    translation: DeTranslation,
  },
  // add more languages as needed
};

const languageDetectorOptions = {
  // order and fromWhere properties are required
  order: ["navigator", "htmlTag", "cookie", "localStorage", "querystring"],
  // keys or params to lookup language from
  lookupLocalStorage: "i18nextLng",
  lookupCookie: "i18nextLng",
  lookupQuerystring: "lng",
  // cache user language on
  caches: ["localStorage"],
  debug: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en-In",
    fallbackLng: "en-In",
    interpolation: {
      escapeValue: false,
    },
    detection: languageDetectorOptions,
  });

// auto detect language on init
i18n.languages = [];

// start with the detected language
i18n.changeLanguage(i18n.language);

export default i18n;
