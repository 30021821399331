import React, { useEffect, useState } from "react";
import { getPetDetails, petAddress } from "../../interface";
import OutsideClickHandler from "react-outside-click-handler";
import GoogleMapReact from "google-map-react";
import Marker from "../../components/marker";

interface Props {
  getPetDetails: getPetDetails;
}

interface AddressCompProps {
  item: petAddress;
  index: number;
}

const AddressComp: React.FC<AddressCompProps> = ({ item, index }) => {
  // const [openMapLocation, setOpenMapLocation] = useState(false);

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 52.52,
      lng: 13.405,
    },
    zoom: 10,
  });

  const openInGoogleMaps = () => {
    const addressQuery = encodeURIComponent(
      `${item.houseNumber} ${item.city} ${item.country}`
    );
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleSearch = (searchString: any) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        searchString
      )}&key=AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;
          setDefaultProps({
            ...defaultProps,
            center: {
              lat: latitude,
              lng: longitude,
            },
          });
        } else {
          console.log("No results found.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="locationbtn" key={index}>
      <span className="address-detail">
        <span className="address-text">{item.name}</span>
        <span className="address-line">{item.houseNumber}</span>
        <span className="address-line">
          {item.pinCode} {item.city}
        </span>
        <span className="address-line">{item.country}</span>
      </span>
      <span className="location-icon" onClick={openInGoogleMaps}>
        <img src="assets/images/location-icon.svg" alt="Pin" />
      </span>

      {/* {openMapLocation && (
        <div className="modal-overlay">
          <div
            className="modal fade common-modal show d-block"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOpenMapLocation(false);
                }}
              >
                <div className="modal-content map-style">
                  <img
                    width={35}
                    className="cross-icon"
                    src="/assets/images/cross-icon.svg"
                    alt=""
                    onClick={() => setOpenMapLocation(false)}
                  />
                  <div style={{ height: "100vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g",
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <Marker
                        lat={defaultProps.center.lat}
                        lng={defaultProps.center.lng}
                        text="My Marker"
                      />
                    </GoogleMapReact>
                  </div>
                  <img
                    onClick={() => {
                      setOpenMapLocation(false);
                    }}
                    className="back-button"
                    src="/assets/images/go-back.svg"
                    alt="go-back"
                  />
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

const AddressSection = ({ getPetDetails }: Props) => {
  return (
    <div className="address-blk" style={{ zIndex: "100 !important" }}>
      {getPetDetails?.petAddress.length === 0
        ? null
        : getPetDetails?.petAddress?.map((items: petAddress, index: number) => {
            return <AddressComp key={index} item={items} index={index} />;
          })}
    </div>
  );
};

export default AddressSection;
