/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import i18n from "../../translation/i18next";

const ExampleView = () => {
  const navigate = useNavigate();
  return (
    <div className="position-relative ">
      <img
        onClick={() => navigate("/")}
        style={{
          position: "fixed",
          left: "10px",
          top: "20px",
          zIndex: "2",
          cursor: "pointer",
        }}
        src="/assets/images/go-back.svg"
        alt="go-back"
        width={45}
      />

      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-blk">
                <div className="logo-blk">
                  <img src="/assets/images/wooftag-clr-logo.svg" alt="Logo" />
                </div>
                <div className="dog-img-outer-blk">
                  <div className="dog-img-blk">
                    <img src="/assets/images/chihuahua.png" alt="Dog" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container g-0">
          <div className="row g-0">
            <div className="col-lg-12">
              <div>
                <div className="dog-name">
                  <h1>sunny</h1>
                </div>
                <div className="dog-breed-name">
                  <p>Chihuahua</p>
                </div>
                <div className="dog-color-name">
                  <p>Blond</p>
                </div>
                <div className="dog-description">
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy sadipscing elitr, sed diam nonumy...
                  </p>
                </div>
                <div className="detail-card-wrapper">
                  <div className="detail-card gr-card">
                    <h2>3 kg</h2>
                    <span className="detail-card-title">weight </span>
                  </div>
                  <div className="detail-card yw-card">
                    <h2>male</h2>
                    <span className="detail-card-title">gender</span>
                  </div>
                  <div className="detail-card bl-card">
                    <h2>8 years</h2>
                    <span className="detail-card-title">age</span>
                  </div>
                </div>
                <div className="send-location-blk border-bottom-0 ">
                  <button
                    className="sendlocation"
                    data-bs-toggle="modal"
                    data-bs-target="#locationModal"
                  >
                    <span className="btn-text">
                      {i18n.t("Send location to owner")}
                    </span>
                    <span className="btn-icon">
                      <img
                        src="/assets/images/send-location-icon.svg"
                        alt="Location"
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div className="call-blk">
                <a className="callaction">
                  <span className="callaction-letter">M</span>
                  <span className="callaction-detail">
                    <span className="callaction-text">
                      {i18n.t("Mom’s Number")}
                    </span>
                    <span className="callaction-number">+00 123 456 789</span>
                  </span>
                  <span className="phone-icon">
                    <img src="assets/images/phone.svg" alt="Phone" />
                  </span>
                </a>
                <a className="callaction">
                  <span className="callaction-letter">D</span>
                  <span className="callaction-detail">
                    <span className="callaction-text">
                      {i18n.t("Dad’s Number")}
                    </span>
                    <span className="callaction-number">+00 587 456 331</span>
                  </span>
                  <span className="phone-icon">
                    <img src="assets/images/phone.svg" alt="Phone" />
                  </span>
                </a>
              </div>

              <div className="address-blk">
                <div className="locationbtn">
                  <span className="address-detail">
                    <span className="address-text">Sunny’s Home</span>
                    <span className="address-line">Street 10</span>
                    <span className="address-line">12345 Berlin </span>
                    <span className="address-line">Germany</span>
                  </span>
                  <span className="location-icon">
                    <img src="assets/images/location-icon.svg" alt="Pin" />
                  </span>
                </div>
              </div>

              <section>
                <div className="container">
                  <div className="row">
                    <div className="d-flex align-items-center flex-column gap-3">
                      <div className="detail-slider-item mt-4">
                        <h2>{i18n.t("Behavior")}</h2>
                        <div className="detail-content-blk">
                          <div className="detail-top-blk">
                            <div className="detail-img-blk">
                              <img src="assets/images/dog.svg" alt="Pet" />
                            </div>
                            <div className="content-detail">
                              <h3>Sunny</h3>
                              <span className="text-blk">
                                {i18n.t("is good with ")}:
                              </span>
                            </div>
                          </div>
                          <div className="inline-content-wrapper">
                            <div className="inline-content-blk">
                              <p>
                                <span className="txt-bold">
                                  {i18n.t("People")} :&nbsp;
                                </span>
                                {i18n.t("Yes")}
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <p>
                                <span className="txt-bold">
                                  {i18n.t("Kids")} :&nbsp;
                                </span>
                                {i18n.t("Yes")}
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <p>
                                <span className="txt-bold">
                                  {" "}
                                  {i18n.t("Dogs")} :&nbsp;
                                </span>
                                {i18n.t("Yes")}
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <p>
                                <span className="txt-bold">
                                  {" "}
                                  {i18n.t("Cats")} :&nbsp;
                                </span>
                                {i18n.t("No")}
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <span className="txt-heading">
                                {i18n.t("Additional informations")} :
                              </span>
                              <p>
                                {i18n.t(
                                  "I may be small, but I have a powerful spirit! With my bold and fearless nature, I strut around like a confident superstar. Don't let my size fool you. I'm the sassy Chihuahua who conquers hearts wherever I go!"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="detail-slider-item">
                        <h2>{i18n.t("Health Informations")}</h2>
                        <div className="detail-content-blk">
                          <div className="detail-top-blk">
                            <div className="detail-img-blk">
                              <img
                                src="assets/images/health.svg"
                                alt="Health"
                              />
                            </div>
                            <div className="content-detail">
                              <h3>Sunny’s</h3>
                              <span className="text-blk">
                                {i18n.t("Health Informations")}
                              </span>
                            </div>
                          </div>
                          <div className="inline-content-wrapper">
                            <div className="inline-content-blk">
                              <span className="txt-heading">
                                {i18n.t("Allergies")} :
                              </span>
                              <p>Potatos</p>
                            </div>
                            <div className="inline-content-blk">
                              <span className="txt-heading">
                                Medication required:
                              </span>
                              <p>
                                I need my heart medication urgently every day
                                (Vetmedin , 2mg )
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <p>
                                <span className="txt-bold">
                                  Neutered / Spayed:&nbsp;
                                </span>
                                Yes
                              </p>
                            </div>
                            <div className="inline-content-blk">
                              <span className="txt-heading">
                                {i18n.t("Additional information")} :
                              </span>
                              <p>
                                I have a heart condition, so my medications are
                                vital to my survival.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="detail-slider-item">
                        <h2>Vet &amp; {i18n.t("Registration")}</h2>
                        <div className="detail-content-blk">
                          <div className="detail-top-blk">
                            <div className="detail-img-blk">
                              <img
                                src="assets/images/vet-plus.svg"
                                alt="Doctor"
                              />
                            </div>
                            <div className="content-detail">
                              <h3>Vet Dr. {i18n.t("Mustermann")}</h3>
                              <span className="text-blk">Berlin Str. 123</span>
                              <span className="text-blk">12345 Berlin</span>
                              <span className="text-blk">+49 30 123 456</span>
                            </div>
                          </div>

                          <div className="call-btn-blk">
                            <a>
                              <button className="commonbtn">
                                <span className="btn-text">
                                  {i18n.t("Call The Vet")}
                                </span>
                                <span className="btn-icon">
                                  <img
                                    src="assets/images/phone-icon.svg"
                                    alt="Phone"
                                  />
                                </span>
                              </button>
                            </a>
                          </div>

                          <div className="inline-content-wrapper d-flex flex-column ">
                            <div className="inline-content-blk d-flex justify-content-between align-items-center">
                              <div>
                                <span className="txt-heading">
                                  {i18n.t("Microchip ID")} :
                                </span>
                                <p>1987 7643 2584 116</p>
                              </div>
                              <div>
                                <img src="/assets/images/copy.svg" alt="" />
                              </div>
                            </div>

                            <div className="inline-content-blk d-flex justify-content-between align-items-center">
                              <div>
                                <span className="txt-heading">
                                  {i18n.t("Tasso ID (TKN )")} :
                                </span>
                                <p>8997 6642</p>
                              </div>
                              <div>
                                <img src="/assets/images/copy.svg" alt="" />
                              </div>
                            </div>

                            <div className="inline-content-blk">
                              <span className="txt-heading">
                                {i18n.t("Registrated at Findefix")} :
                              </span>
                              <p>{i18n.t("Yes")}</p>
                            </div>
                          </div>
                          <div className="search-btn-blk">
                            <button className="commonbtn">
                              <span className="btn-text">
                                {i18n.t("Search at Findefix")}
                              </span>
                              <span className="btn-icon">
                                <img
                                  src="assets/images/search.svg"
                                  alt="Search"
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <div
        className="modal fade common-modal"
        id="locationModal"
        tabIndex={-1}
        aria-labelledby="locationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="location-modal-block">
                <h2>{i18n.t("Location Sharing")}</h2>
                <p>
                  {i18n.t(
                    "Your current GPS location will be sent to {petName} owners. {petName} pet owners will only be able to view your location at the moment of sending, and it will not be continuously monitored."
                  )}
                </p>
                <p>
                  {i18n.t(
                    "A notification will appear, requesting permissions to access your location once. Kindly click the 'Allow' button to ensure safe return home."
                  )}
                </p>
                <div className="condition-btns-blk">
                  <button className="accept-btn">
                    {i18n.t("Yes, I Understand")}
                    <span className="md-paw">
                      <img src="assets/images/paw.png" alt="paw" />
                    </span>
                  </button>
                  <button className="cancel-btn">{i18n.t("Cancel")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleView;
