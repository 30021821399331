import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("uid");

  useEffect(() => {
    axios
      .post(`${baseUrl}web/pet/detail`, {
        uid: page,
        lat: null,
        lng: null,
        address: null,
      })
      .then((res) => {
        navigate("/profile-details", {
          replace: true,
          state: { data: JSON.stringify(res) },
        });
      })
      .catch((err: any) => {
        navigate("/profile-details", {
          replace: true,
          state: { error: JSON.stringify(err) },
        });
      });
  }, []);

  return (
    <div className="loader-main">
      <img width={180} src="/assets/images/woofAnimtedLoading.gif" alt="" />
    </div>
  );
}
