/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { getPetDetails } from "../../interface";
import CustoModal from "../../components/custoModal";
import PhoneNumberSection from "./phoneNumberSection";
import AddressSection from "./addressSection";
import LocationSection from "./sendLocationSection";
import OutsideClickHandler from "react-outside-click-handler";
import { useFormik } from "formik";
import {
  enterAddressInitialValues,
  enterAddressValidationSchema,
} from "../../validation";
import axios from "axios";
import alertTost from "../../components/AlertToast";
import i18n from "../../translation/i18next";

interface Props {
  getPetDetails: getPetDetails;
  openLocationModal: boolean;
  setOpenLocationModal: (value: boolean) => void;
  getCurrentLocation: () => void;
}

const TopSection = ({
  getPetDetails,
  openLocationModal,
  setOpenLocationModal,
  getCurrentLocation,
}: Props) => {
  // const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [age, setAge] = useState<number | null>(null);
  const [isLoading, setSetIsloading] = useState<boolean>(false);
  const [selectLocation, setSelectLocation] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const [latlng, setLatLng] = useState<any>({
    lat: 52.52,
    lng: 13.405,
  });
  const [openMap, setOpenMap] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageBaseUrl = process.env.REACT_APP_PET_IMAGES_URL;

  // handle vaidation on input using formik
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: enterAddressInitialValues,
    validationSchema: enterAddressValidationSchema,
    onSubmit: async () => {
      setSetIsloading(true);
      try {
        const resposne = await axios.post(`${baseUrl}/web/sendlocation`, {
          petId: getPetDetails?.petDetail?._id || null,
          lat: latlng.lat,
          lng: latlng.lng,
          address: values.enterAddress,
        });
        if (resposne) {
          setSetIsloading(false);
          resetForm();
          setShowInput(false);
          alertTost("address send successfully", "success");
          setLatLng({
            lat: "",
            lng: "",
          });
        } else {
          alertTost("Missing field", "error");
          setSetIsloading(false);
        }
      } catch (error) {
        alertTost("Something went wrong", "error");
        setSetIsloading(false);
      }
    },
  });

  // handle Confirm function
  const handleModalConfirm = async () => {
    setSetIsloading(true);
    try {
      const resposne = await axios.post(`${baseUrl}web/sendlocation`, {
        petId: getPetDetails?.petDetail?._id || null,
        lat: latlng.lat,
        lng: latlng.lng,
        address: values.enterAddress,
      });
      if (resposne) {
        setOpenMap(false);
        setOpenLocationModal(false);
        setSetIsloading(false);
        alertTost("location send successfully", "success");
        setLatLng({
          lat: "",
          lng: "",
        });
        setSelectLocation(false);
      } else {
        alertTost("Missing field", "error");
        setSetIsloading(false);
      }
    } catch (error) {
      alertTost("Something went wrong", "error");
      setSetIsloading(false);
    }
  };

  // handleCloseMapModal function
  const handleCloseMapModal = () => {
    setLatLng("");
    setOpenMap(false);
    setOpenLocationModal(false);
    setSetIsloading(false);
    setSelectLocation(false);
  };

  // handleOpenModalInput function
  const handleOpenModalInput = () => {
    setOpenLocationModal(false);
    setShowInput(true);
  };

  const dob = getPetDetails?.petDetail?.dob;

  const GpsText = i18n.t(
    "Your current GPS location will be sent to {petName} owners. {petName} pet owners will only be able to view your location at the moment of sending, and it will not be continuously monitored.",
    getPetDetails?.petDetail?.name
  );

  const NotifyText = i18n.t(
    "A notification will appear, requesting permissions to access your location once. Kindly click the 'Allow' button to ensure safe return home.",
    getPetDetails?.petDetail?.name
  );

  const enterAddressText = i18n.t(
    "Where did you find",
    getPetDetails?.petDetail?.name
  );

  const foundPetText = i18n.t(
    "Type in the address where you found",
    getPetDetails?.petDetail?.name
  );

  // Get age from DOB with this format 22/10/2022
  useEffect(() => {
    const parts = dob?.split("/");
    if (parts?.length === 3) {
      const dobDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );
      const today = new Date();
      const ageDiff = today.getFullYear() - dobDate.getFullYear();

      if (
        today.getMonth() < dobDate.getMonth() ||
        (today.getMonth() === dobDate.getMonth() &&
          today.getDate() < dobDate.getDate())
      ) {
        setAge(ageDiff - 1);
      } else {
        setAge(ageDiff);
      }
    } else {
      setAge(null);
    }
  }, [dob]);

  // handleCloseInputModal function
  const handleCloseInputModal = () => {
    setShowInput(false);
    resetForm();
  };

  return (
    <div>
      <section className="content-section">
        <div className="container g-0">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="dog-name">
                <h1>{getPetDetails?.petDetail?.name}</h1>
              </div>
              <div className="dog-breed-name">
                <p>{getPetDetails?.petDetail?.breed}</p>
              </div>
              <div className="dog-color-name">
                <p>{getPetDetails?.petDetail?.color}</p>
              </div>
              <div className="dog-description">
                <p>{getPetDetails?.petDetail?.info}</p>
              </div>
              <div className="detail-card-wrapper">
                <div className="detail-card gr-card">
                  <h2>{getPetDetails?.petDetail?.weight} Kg</h2>
                  <span className="detail-card-title">{i18n.t("Weight")}</span>
                </div>
                <div className="detail-card yw-card">
                  <h2>{getPetDetails?.petDetail?.gender}</h2>
                  <span className="detail-card-title">{i18n.t("Gender")}</span>
                </div>
                <div className="detail-card bl-card">
                  <h2>
                    {age} {i18n.t("Years")}
                  </h2>
                  <span className="detail-card-title">{i18n.t("Age")}</span>
                </div>
              </div>
              <LocationSection setOpenLocationModal={setConfirmationModal} />
              <PhoneNumberSection getPetDetails={getPetDetails} />
              <AddressSection getPetDetails={getPetDetails} />
            </div>
          </div>
        </div>
      </section>
      {confirmationModal && (
        <div className="modal-overlay">
          <div
            className="modal fade common-modal show d-block"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center ">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <div className="location-modal-block">
                    <h1 className="fw-bold">{i18n.t("Location Sharing")}</h1>
                    <p className="mb-0 fw-normal">
                      {GpsText.replace(
                        /{petName}/g,
                        `${getPetDetails?.petDetail?.name}`
                      )}
                      {/* Your current GPS location will be sent to
                      <b>{getPetDetails?.petDetail?.name}</b> owners. pet{" "}
                      <b>{getPetDetails?.petDetail?.name}</b> owners will only
                      be able to view your location at the moment of sending,
                      and it will not be continuously monitored. */}
                    </p>
                    <p>
                      {NotifyText.replace(
                        /{dogName}/g,
                        `${getPetDetails?.petDetail?.name}`
                      )}
                      {/* A notification will appear, requesting permissions to
                      access your location once. Kindly click the "Allow" button
                      to ensure {getPetDetails?.petDetail?.name} safe return
                      home. */}
                    </p>
                    <div className="condition-btns-blk pointer-event">
                      <button
                        className="accept-btn"
                        onClick={() => {
                          setOpenLocationModal(true);
                          setConfirmationModal(false);
                          getCurrentLocation();
                        }}
                      >
                        {i18n.t("Yes, I Understand")}
                        <span className="md-paw">
                          <img
                            src="assets/images/white_image_paw.svg"
                            alt="map"
                          />
                        </span>
                      </button>
                      <button
                        className="cancel-btn pointer-event"
                        onClick={() => setConfirmationModal(false)}
                      >
                        {i18n.t("Cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustoModal
        selectLocation={selectLocation}
        setSelectLocation={setSelectLocation}
        getPetDetails={getPetDetails}
        handleCloseMapModal={handleCloseMapModal}
        handleConfirm={handleModalConfirm}
        openLocationModal={openLocationModal}
        setOpenLocationModal={setOpenLocationModal}
        setLatLng={setLatLng}
        latlng={latlng}
        openMap={openMap}
        setOpen={setOpenMap}
        handleOpenModalInput={handleOpenModalInput}
        isLoading={isLoading}
      />

      {showInput && (
        <div className="modal-overlay">
          <div
            className="modal fade common-modal show d-block "
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <img
                width={35}
                className="cross-icon"
                src="/assets/images/cross-icon.svg"
                alt=""
                onClick={handleCloseInputModal}
              />
              <OutsideClickHandler onOutsideClick={handleCloseInputModal}>
                <div className="modal-content  map-input-style">
                  <div className="dog-img-inner-location">
                    <img
                      src={`${imageBaseUrl}${getPetDetails?.petDetail.image}`}
                      alt="dog"
                    />
                  </div>
                  <h3>
                    {enterAddressText.replace(
                      /{name}/g,
                      `${getPetDetails?.petDetail?.name}`
                    )}
                  </h3>
                  <p
                    className={`${
                      errors.enterAddress && touched.enterAddress
                        ? "validationError"
                        : ""
                    }`}
                  >
                    {` ${
                      errors.enterAddress && touched.enterAddress
                        ? "Please"
                        : ""
                    }  
                  ${foundPetText.replace(
                    /{pet}/g,
                    `${getPetDetails?.petDetail?.name}`
                  )}
                 `}
                  </p>
                  <textarea
                    rows={6}
                    placeholder="Berlin 58A, 104, Berlin"
                    name="enterAddress"
                    value={values.enterAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="d-flex justify-content-end gap-1 mt-1">
                    <button
                      className="accept-btn"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img
                          width={30}
                          src="/assets/images/woofAnimtedLoading.gif"
                          alt=""
                        />
                      ) : (
                        i18n.t("Confirm & Send")
                      )}
                      <span className="md-paw">
                        <img
                          className="send-icon"
                          style={{ filter: "invert(1)" }}
                          src="assets/images/send.png"
                          alt="send"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopSection;
