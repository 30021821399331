import React from "react";
import { getPetDetails } from "../../interface";
import BehaviorSection from "./behaviorSection";
import HealthInformationSection from "./healthInformationSection";
import RegisterationSection from "./registerationSection";

type Props = {
  getPetDetails: getPetDetails;
};

const BottomSection = ({ getPetDetails }: Props) => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center flex-column gap-3">
            <BehaviorSection getPetDetails={getPetDetails} />
            <HealthInformationSection getPetDetails={getPetDetails} />
            <RegisterationSection getPetDetails={getPetDetails} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomSection;
