import React from 'react'
interface IProps {
    lat: any
    lng: any
    text: string
}

function Marker(props: IProps) {

    return (
        <div className="SuperAwesomePin">
            <img src="/assets/images/map-pin.png" alt="..." height={25} width={25} />
        </div>
    )
}

export default Marker;